/*
 * 0-1. clearfix
 */
@mixin clearfix {
    &:before, &:after { content:""; display:table; }
    &:after { clear:both; }
    zoom:1;
}
/*
 * 0-2. hideText
 */
.hideText { text-indent: 100%; white-space: nowrap; overflow: hidden; }

/*
 * 0-3. breakWord
 */
.breakWord { word-wrap: break-word; }

/*
 * 0-4. upperCase
 */
.upperCase { text-transform: uppercase; }
