@mixin font-family-futura-italic {
  font-family: futura-pt, sans-serif;
  font-weight: 500;
  font-style:italic;
}

@mixin font-family-futura {
  font-family: futura-pt, sans-serif;
  font-weight: 500;
  font-style:normal;
}

@mixin font-family-a-otf-futogo {
  font-family: a-otf-futo-go-b101-pr6n, sans-serif;
  font-weight: 500;
  font-style: normal;
}
